.container {
    display: flex;
    justify-content: center;
    /* height: 85vh; */
    font-size: 14px;
    height: 90vh;
  
  
    @media (min-width: 769px) {
      font-size: 16px; // Text size for desktop
    }
  }
  
  .container_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    width: 85%;
    text-align: center;
    margin-bottom: 50px;
  }
  .chat{
    height:100%;
    width: 100%;
  }

  .container_input{
    width: 100%;
    display: grid;
    grid-template-columns: 17fr 1fr;
    margin-top: 20px;

  }

  
  .container_input .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }