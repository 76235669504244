.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* Adjust as needed */
  font-size: 14px;
  width: 100%;
  
    @media (min-width: 769px) {
      font-size: 16px; // Text size for desktop
    }

    .summary {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 100%;
      padding: 51px;
      .summarygroup{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
  
      h2 {
        
        margin: 0;
        margin-right: 1rem;
      }
  
      .summarycount { 
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: bold;
        font-size: 3rem;
        margin-right: 1rem;
      }
  
      .statusbuttons {
        display: flex;
  
        button {
          margin-right: 0.5rem;
          padding: 0.5rem 1rem;
          border: none;
          cursor: pointer;
          border-radius: 4px;
  
          &.active {
            background-color: #4CAF50; /* Example color */
            color: white;
          }
  
          &.incompleted {
            background-color: #FF5722; /* Example color */
            color: white;
          }
        }
      }
    }
  }