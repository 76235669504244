.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 85vh; */
  font-size: 14px;


  @media (min-width: 769px) {
    font-size: 16px; // Text size for desktop
  }
}

.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px 0px;
  width: 85%;
  text-align: center;
  margin-bottom: 50px
}


.video_container{
  margin-top: 30px;
  width: 100%;
  height: 30vh;
  text-align: center;
  overflow: hidden;
}

.videobcg{
  width: inherit;
  height: inherit;
  -o-filter: blur(15px);
  filter: blur(0px);
  object-fit: cover;
  transform: scale(1.04);
}  