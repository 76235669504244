.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  /* Adjust as needed */
  font-size: 14px;

  @media (min-width: 769px) {
    font-size: 16px; // Text size for desktop
  }
}

.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  width: 85%;
  text-align: center;
  margin-bottom: 50px
}