.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* Adjust as needed */
    font-size: 14px;
    width: 100%;
    margin-bottom: 50px;

    @media (min-width: 769px) {
        font-size: 16px; // Text size for desktop
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        width: 70%;
    }


    .textField {
        margin-bottom: 16px;
    }

    .questionContainer {
        display: flex;
        align-items: center;
        margin-top: 32px;
    }



    .questionsList {
        width: 100%;
        margin-top: 16px;
    }
    
    .questionItem {
        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;
    }
    
    .dragIcon {
        cursor: move;
        margin-right: 8px;
    }
    
    .questionText {
        flex-grow: 1;
    }

    



}


