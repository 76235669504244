.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  /* Adjust as needed */
  font-size: 14px;

  @media (min-width: 769px) {
    font-size: 16px; // Text size for desktop
  }
}

.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px;
  width: 85%;
  text-align: center;
  margin-bottom: 50px;
  z-index: 1000;
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    z-index: -1;
    border-radius: 10px;
  }
}