.container {
    width: 40%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
  }

.circle_container {
    $particleNum: 500;
    $particleColor: hsl(180, 61%, 29%);
    position: absolute;
    transform: translateY(-10vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: screen;
        background-image: radial-gradient(#0dc9cc3d, #2dc7d23b,
        hsla(180, 81%, 45%, 0) 35%);

        animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

        @keyframes fade-frames {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0.7;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes scale-frames {
            0% {
                transform: scale3d(0.4, 0.4, 1);
            }

            50% {
                transform: scale3d(2.2, 2.2, 1);
            }

            100% {
                transform: scale3d(0.4, 0.4, 1);
            }
        }
    }

    $particleBaseSize: 60;

    @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
            $circleSize: random($particleBaseSize);
            width: $circleSize + px;
            height: $circleSize + px;

            $startPositionY: random(40) + 100;
            $framesName: "move-frames-" + $i;
            $moveDuration: 28000 + random(9000) + ms;

            animation-name: #{$framesName};
            animation-duration: $moveDuration;
            animation-delay: random(37000) + ms;

            @keyframes #{$framesName} {
                from {
                    transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
                }

                to {
                    transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
                }
            }

            .circle {
                animation-delay: random(4000) + ms;
            }
        }
    }
}