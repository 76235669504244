.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 85vh; */
  font-size: 14px;


  @media (min-width: 769px) {
    font-size: 16px; // Text size for desktop
  }
}

.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px 0px;
  width: 85%;
  text-align: center;
  margin-bottom: 50px
}

.button_recording {
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;


}

.button_stoprecording {
  background-color: rgb(202, 114, 114) !important;
  color: rgb(212, 191, 191)
}

.icon_button{
  margin-right: 8px;
}